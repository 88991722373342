<template>
    <div class="row" @click="handleClickOutside">
      <div class="col-md-5 mb-3 mb-md-0 p-0" >
        <div class="clients info-block" v-for="(block, blockName) in formData" :key="blockName">
          <div class="block">
            <div class="head">
              <h2>{{ block.title }}</h2>
            </div>
            <div class="body">
              <div class="interactive-form">
                <div v-for="(field, fieldName) in block.fields" :key="fieldName">
                  <div v-if="!editing || editField !== fieldName" @click="startEditing(fieldName, blockName)">
                    <div class="row">
                      <template v-if="fieldName == 'date_of_birth'">
                        <div v-if="formData.personalInfo.fields.client_type.value != 2" class="col-6 text-left label-field grey">
                          {{ field.label }}:
                        </div>
                      </template>
                      <div v-else class="col-6 text-left label-field grey">{{ field.label }}:</div>

                      <div  v-if="fieldName == 'client_type'"  class="col-6 text-right text-field"> <div class="text-label">{{ showUserType(field) }}</div></div>
                      <div  v-else-if="fieldName == 'city_id'"  class="col-6 text-right text-field"> <div class="text-label">{{ field.value.name_full_name_ua || "" }}</div></div>
                      <div  v-else-if="(fieldName == 'user_id' && field.user_parent_id == null)"  class="col-6 text-right text-field"> <div class="text-label" v-html="showAgent(field)"></div></div>
                      <div  v-else-if="fieldName == 'user_id' && field.user_parent_id != null"  class="col-6 text-right text-field"> <div class="text-label-none-edit" v-html="showAgent(field)"></div></div>
                      <div v-else-if="fieldName == 'date_of_birth'" class="col-6 text-right text-field">
                        <div v-if="formData.personalInfo.fields.client_type.value != 2" :class="['text-label', {invalid: errorTaxNumber()}]">{{ field.value }}</div>
                      </div>
                      <div v-else-if="fieldName == 'phone'" class="col-6 text-right text-field">
                        <div :class="['text-label',{invalid: !$v.formData.contactInfo.fields.phone.value.customMinLength}]">{{ field.value }}</div>
                      </div>
                      <div v-else-if="fieldName == 'email'" class="col-6 text-right text-field">
                        <div :class="['text-label',{invalid: !$v.formData.contactInfo.fields.email.value.email}]">{{ field.value }}</div>
                      </div>
                      <div v-else-if="fieldName == 'contact_persone_phone'" class="col-6 text-right text-field">
                        <div :class="['text-label',{invalid: !$v.formData.contactPerson.fields.contact_persone_phone.value.customMinLength}]">{{ field.value }}</div>
                      </div>
                      <template v-else-if="fieldName == 'identification_number'">
                        <div v-if="formData.personalInfo.fields.client_type.value == 2" class="col-6 text-right text-field">
                          <div :class="['text-label',{invalid: !$v.formData.personalInfo.fields.identification_number.value.customMinLength}]">{{ field.value }}</div>
                        </div>
                        <div v-else-if="formData.personalInfo.fields.client_type.value != 2" class="col-6 text-right text-field">
                          <div :class="['text-label', {invalid: errorTaxNumber() || !$v.formData.personalInfo.fields.identification_number.value.customMinLength}]">{{ field.value }}</div>
                        </div>
                      </template>
                      <div  v-else  class="col-6 text-right text-field"> <div class="text-label">{{ field.value }}</div></div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="row">
                      <div class="col-6 text-left label-field grey"><label for="">{{ field.label }}:</label></div>
                      <div class="col-6 text-right text-field-edit">
                        <select v-if="fieldName == 'client_type'" v-model="formBuffer[blockName].fields[fieldName].value" @change="saveField(fieldName, blockName)">
                          <option v-for="type in userTypesList" :key="type.id" :value="type.id">{{type.name}}</option>
                        </select>
                        <el-select  v-else-if="fieldName == 'user_id'" ref="agentSelect" v-model="formBuffer[blockName].fields[fieldName].value"
                                   @visible-change="handleVisibleChange"
                                   :data-field-name="fieldName" :data-block-name="blockName"
                                   class="form-control-el"
                                   multiple
                                    collapse-tags
                                    collapse-tags-tooltip
                                    :max-collapse-tags="3"
                                   placeholder="Оберіть відповідального">
                          <el-option v-for="item in field.usersData"
                                     :key="item.id"
                                     :label="(item.name?item.name:'') + ' ' + (item.surname?item.surname:'') + ' ' + (item.patronymic?item.patronymic:'')"
                                     :type="item.type"
                                     :value="item.id"
                          >
                          </el-option>
                        </el-select>
                        <div class="form-group" v-else-if="fieldName == 'date_of_birth'" >
                          <input v-mask="'##.##.####'" placeholder="Дата народження" @blur="saveField(fieldName, blockName)" @keydown.enter="saveField(fieldName, blockName)"
                                  v-model="formBuffer[blockName].fields[fieldName].value"
                                  :class="['input_'+blockName+'_'+fieldName, {invalid: errorBirthDate() || errorTaxNumber()}]">
                          <small class="error" v-if="errorTaxNumber()">
                            ІПН та Дата народження не співпадають
                          </small>
                        </div>

                        <div class="form-group" v-else-if="fieldName == 'identification_number' && formData.personalInfo.fields.client_type.value != 2" >
                          <input v-mask="'##########'" :class="['input_'+blockName+'_'+fieldName,{invalid: errorTaxNumber() || !$v.formData.personalInfo.fields.identification_number.value.customMinLength}]"  v-model="formBuffer[blockName].fields[fieldName].value" @blur="saveField(fieldName, blockName)" @keydown.enter="saveField(fieldName, blockName)">
                          <small class="error" v-if="errorTaxNumber()">
                            ІПН та Дата народження не співпадають
                          </small>
                          <small class="error" v-else-if="!$v.formData.personalInfo.fields.identification_number.value.customMinLength">
                            ІПН повинен бути довжиной 10 цифр
                          </small>
                        </div>
                        <div class="form-group" v-else-if="fieldName == 'identification_number' && formData.personalInfo.fields.client_type.value == 2" >
                          <input v-mask="'########'" :class="[{invalid: !$v.formData.personalInfo.fields.identification_number.value.customMinLength}]" v-model="formBuffer[blockName].fields[fieldName].value" type="text" placeholder="ЄДРПОУ" @blur="saveField(fieldName, blockName)" @keydown.enter="saveField(fieldName, blockName)">
                          <small class="error" v-if="!$v.formData.personalInfo.fields.identification_number.value.customMinLength">
                            ЄДРПОУ повинен бути довжиной 8 цифр
                          </small>
                        </div>

                        <div class="form-group" v-else-if="fieldName == 'phone'" >
                          <input v-mask="'+38 (###) ###-##-##'" :class="[{invalid: !$v.formData.contactInfo.fields.phone.value.customMinLength}]"  v-model="formBuffer[blockName].fields[fieldName].value" @blur="saveField(fieldName, blockName)" @keydown.enter="saveField(fieldName, blockName)">
                          <small class="error" v-if="!$v.formData.contactInfo.fields.phone.value.customMinLength">Введіть номер в форматі 38(0xx)xxx-xx-xx</small>
                        </div>
                        <div class="form-group" v-else-if="fieldName == 'email'" >
                          <input :class="[{invalid: !$v.formData.contactInfo.fields.email.value.email}]"  v-model="formBuffer[blockName].fields[fieldName].value" @blur="saveField(fieldName, blockName)" @keydown.enter="saveField(fieldName, blockName)">
                          <small class="error" v-if="!$v.formData.contactInfo.fields.email.value.email">Введіть коректно email</small>
                        </div>
                        <div class="form-group" v-else-if="fieldName == 'contact_persone_phone'" >
                          <input v-mask="'+38 (###) ###-##-##'" :class="[{invalid: !$v.formData.contactPerson.fields.contact_persone_phone.value.customMinLength}]"  v-model="formBuffer[blockName].fields[fieldName].value" @blur="saveField(fieldName, blockName)" @keydown.enter="saveField(fieldName, blockName)">
                          <small class="error" v-if="!$v.formData.contactPerson.fields.contact_persone_phone.value.customMinLength">Введіть номер в форматі 38(0xx)xxx-xx-xx</small>
                        </div>
                        <input v-else-if="fieldName == 'name_en'"  v-model="formBuffer[blockName].fields[fieldName].value"
                               maxlength="30"  @blur="saveField(fieldName, blockName)" @keydown.enter="saveField(fieldName, blockName)"
                               type="text"
                               v-on:keypress="LetterMask($event)"
                               placeholder="ПІБ (Латиницею)"
                               :class="['form-control', {invalid: (submitCheck && !onlyLatinCharacters(formBuffer[blockName].fields[fieldName].value))}]">
                        <input v-else-if="fieldName == 'city_en'"  v-model="formBuffer[blockName].fields[fieldName].value"
                               maxlength="30"  @blur="saveField(fieldName, blockName)" @keydown.enter="saveField(fieldName, blockName)"
                               type="text"
                               v-on:keypress="LetterMask($event)"
                               placeholder="Місто (Латиницею)"
                               :class="['form-control', {invalid: (submitCheck && !onlyLatinCharacters(formBuffer[blockName].fields[fieldName].value))}]">
                        <input v-else-if="fieldName == 'street_en'"  v-model="formBuffer[blockName].fields[fieldName].value"
                               maxlength="30"  @blur="saveField(fieldName, blockName)" @keydown.enter="saveField(fieldName, blockName)"
                               type="text"
                               v-on:keypress="LetterMask($event)"
                               placeholder="Вулиця (Латиницею)"
                               :class="['form-control', {invalid: (submitCheck && !onlyLatinCharacters(formBuffer[blockName].fields[fieldName].value))}]">
                        <div class="form-group" v-else-if="fieldName == 'city_id'">
                          <multiselect @blur="saveField(fieldName, blockName)"
                              :class="['form-control', 'choice_city']"
                              v-model="formBuffer[blockName].fields[fieldName].value"
                              :options="citiesOptions"
                              placeholder="Місце реєстрації власника ТЗ"
                              @search-change="searchCities"
                              @select="saveField(fieldName, blockName)"
                              :internal-search="false"
                              :max-height="165"
                              :options-limit="300"
                              :allow-empty="false"
                              label="name_full_name_ua">
                          </multiselect>
                        </div>
                        <input v-else v-model="formBuffer[blockName].fields[fieldName].value" @blur="saveField(fieldName, blockName)" @keydown.enter="saveField(fieldName, blockName)">
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-md-block col-md-fixed  mb-3">
        <div class="vr"></div>
      </div>
      <div class="col pr-0 p-0">
        <div class="clients info-block" v-for="(block, blockName) in formDataNote" :key="blockName">
          <div class="block">
            <div class="head">
              <div class="row">
                <div class="col-auto  pl-3"> <h2>{{ block.title }}</h2></div>
                <div class="col-auto ml-auto">
                  <button @click="createNotice()">
                    <div v-if="isCreatingNotice"
                         class="option-loader spinner-border"
                         style="height: 22px; width: 22px; margin-right: 12px;"
                         role="status">
                    </div>
                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_557_377)">
                        <rect x="4" y="1.80005" width="30" height="28.2" rx="3" fill="#FFAD3D"/>
                        <rect x="4" width="30" height="28.2" rx="3" fill="#FFAD3D"/>
                      </g>
                      <rect x="18.3999" y="9" width="1.2" height="12" fill="white"/>
                      <rect x="25" y="14.4" width="1.2" height="12" transform="rotate(90 25 14.4)" fill="white"/>
                      <defs>
                        <filter id="filter0_d_557_377" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="4"/>
                          <feGaussianBlur stdDeviation="2"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_557_377"/>
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_557_377" result="shape"/>
                        </filter>
                      </defs>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="body">
              <div class="interactive-form">
                <div v-for="field in block.data" :key="field.id">
                  <div class="row">
                    <div class="col-6 text-left label-field">{{ field.note }}</div>
                    <div class="col-3 text-right text-field grey">{{ field.agent.name + " " +  field.agent.surname + " " +  field.agent.patronymic  }}</div>
                    <div class="col-3 text-right text-field grey">
                      <div class="row">
                        <div class="col date-field">{{ showDateFormat(field) }}</div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <button @click="editNote(field)">
                            <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.5146 4.2276C11.8039 3.9383 11.9631 3.5541 11.9631 3.14541C11.9631 2.73671 11.8039 2.35251 11.5146 2.06321L10.3008 0.849371C10.0115 0.560071 9.62728 0.400879 9.21858 0.400879C8.80989 0.400879 8.42569 0.560071 8.13715 0.848606L0 8.9605V12.3395H3.37747L11.5146 4.2276ZM9.21858 1.93157L10.4332 3.14464L9.21629 4.35695L8.00245 3.14388L9.21858 1.93157ZM1.53069 10.8088V9.59574L6.91872 4.22454L8.13256 5.43838L2.74529 10.8088H1.53069ZM0 13.8702H12.2455V15.4009H0V13.8702Z" fill="#DDE2E5"/>
                            </svg>
                          </button>
                          <button @click="confirmDelete(field)">
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.5 13.5C1.5 14.3273 2.17275 15 3 15H10.5C11.3273 15 12 14.3273 12 13.5V4.5H13.5V3H10.5V1.5C10.5 0.67275 9.82725 0 9 0H4.5C3.67275 0 3 0.67275 3 1.5V3H0V4.5H1.5V13.5ZM4.5 1.5H9V3H4.5V1.5ZM3.75 4.5H10.5L10.5007 13.5H3V4.5H3.75Z" fill="#DDE2E5"/>
                              <path d="M4.5 6H6V12H4.5V6ZM7.5 6H9V12H7.5V6Z" fill="#DDE2E5"/>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

<!--
        <div class="clients info-block" v-for="(block, blockName) in formDataHistory" :key="blockName">
          <div class="block">
            <div class="head">
              <div class="row">
                <div class="col-auto  pl-3"> <h2>{{ block.title }}</h2></div>
                <div class="col-auto ml-auto">
                  <button @click="createHistory()">
                    <div v-if="isCreatingHistory"
                         class="option-loader spinner-border"
                         style="height: 22px; width: 22px; margin-right: 12px;"
                         role="status">
                    </div>
                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_557_377)">
                        <rect x="4" y="1.80005" width="30" height="28.2" rx="3" fill="#FFAD3D"/>
                        <rect x="4" width="30" height="28.2" rx="3" fill="#FFAD3D"/>
                      </g>
                      <rect x="18.3999" y="9" width="1.2" height="12" fill="white"/>
                      <rect x="25" y="14.4" width="1.2" height="12" transform="rotate(90 25 14.4)" fill="white"/>
                      <defs>
                        <filter id="filter0_d_557_377" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="4"/>
                          <feGaussianBlur stdDeviation="2"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_557_377"/>
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_557_377" result="shape"/>
                        </filter>
                      </defs>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="body">
              <div class="interactive-form">
                <div v-for="field in block.data" :key="field.id">
                  <div class="row">
                    <div class="col-6 text-left label-field">{{ field.note }}</div>
                    <div class="col-3 text-right text-field">{{ field.agent.name + " " +  field.agent.surname + " " +  field.agent.patronymic  }}</div>
                    <div class="col-3 text-right text-field">
                      <div class="row">
                        <div class="col">{{ showDateFormat(field) }}</div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <button @click="editNote(field)">
                            <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.5146 4.2276C11.8039 3.9383 11.9631 3.5541 11.9631 3.14541C11.9631 2.73671 11.8039 2.35251 11.5146 2.06321L10.3008 0.849371C10.0115 0.560071 9.62728 0.400879 9.21858 0.400879C8.80989 0.400879 8.42569 0.560071 8.13715 0.848606L0 8.9605V12.3395H3.37747L11.5146 4.2276ZM9.21858 1.93157L10.4332 3.14464L9.21629 4.35695L8.00245 3.14388L9.21858 1.93157ZM1.53069 10.8088V9.59574L6.91872 4.22454L8.13256 5.43838L2.74529 10.8088H1.53069ZM0 13.8702H12.2455V15.4009H0V13.8702Z" fill="#DDE2E5"/>
                            </svg>
                          </button>
                          <button @click="confirmDelete(field)">
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.5 13.5C1.5 14.3273 2.17275 15 3 15H10.5C11.3273 15 12 14.3273 12 13.5V4.5H13.5V3H10.5V1.5C10.5 0.67275 9.82725 0 9 0H4.5C3.67275 0 3 0.67275 3 1.5V3H0V4.5H1.5V13.5ZM4.5 1.5H9V3H4.5V1.5ZM3.75 4.5H10.5L10.5007 13.5H3V4.5H3.75Z" fill="#DDE2E5"/>
                              <path d="M4.5 6H6V12H4.5V6ZM7.5 6H9V12H7.5V6Z" fill="#DDE2E5"/>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
-->

      </div>
      <modal-edit-note :show-modal="showModal" @close="showModal = false" :note-to-edit="noteToEdit" @save="saveNote" @delete="deleteNote"/>
      <vue-confirm-dialog></vue-confirm-dialog>
    </div>
</template>



<script>
import {email, minLength, required} from "vuelidate/lib/validators";
import {deleteClientNotes, getData as getClientNotes, saveClientNotes} from "@/api/clientNotes";
import {deleteClient, getData, updateClient} from "@/api/client";
import {ModalEditNote} from "@/views/clients/components/index";
import Vue from "vue";
import citySearch from "@/components/app/forms/CitySearch.vue";
import Multiselect from "vue-multiselect";
import {getByParams as getInsuredByParams} from "@/api/insured";
export default {
  components: {Multiselect, citySearch, ModalEditNote},
  props: [],
  data() {
    return {
      isCreatingNotice: false,
      isCreatingHistory: false,
      submitCheck:false,
      errors:[],
      formBuffer: {},
      noteToEdit: null,
      showModal: false,
      condition: {
        isLoadClientData: false,
        isLoadTable: true,
        isLoadXml: false,
        isMobile: false,
      },
      userTypesList: [
            { name: 'Фіз.особа', id: 1 },
            { name: 'Юр.особа', id: 2 },
            { name: 'Пільговик', id: 3 }
      ],
      formData: {
        personalInfo: {
          title: 'Дані клієнта',
          fields: {
            name: { value: '', label: 'ПІБ' },
            name_en: { value: '', label: 'ПІБ (Латиницею)' },
            client_type: { value: '', label: 'Тип клієнта' },
            identification_number: { value: '', label: 'ІПН/ЄДРПОУ'},
            date_of_birth: { value: '', label: 'Дата народження' },
          }
        },
        contactInfo: {
          title: 'Контакти',
          fields: {
            phone: { value: '', label: 'Телефон' },
            email: { value: '', label: 'Пошта' },
            city_id: { value: '', label: 'Місто' },
            city_en: { value: '', label: 'Місто (Латиницею)' },
            street: { value: '', label: 'Вулиця' },
            street_en: { value: '', label: 'Вулиця (Латиницею)' },
            house: { value: '', label: 'Номер будинку' },
            flat: { value: '', label: 'Квартира' },
          }
        },
        contactPerson: {
          title: 'Контактна особа',
          fields: {
            contact_persone_name: { value: '', label: 'Контактна особа' },
            contact_persone_phone: { value: '', label: 'Телефон контактної особи' }
          }
        },
        agent: {
          title: 'Відповідальний',
          fields: {
            user_id: { value: '', user_parent_id: null, usersData: [], label: 'Точка продажу' },
          }
        }
      },
      formDataNote: {
        notes: {
          title: 'Нотатки',
          data: {}
        },
      },
      formDataHistory: {
        history: {
          title: 'Історія взаємодій',
          data: {}
        },
      },
      editing: false,
      editField: '',
      citiesOptions: [ {"id": 1,"name": "Київ","name_full_name_ua": "м. Київ, Україна", "zone":1},
        {"id": 9,"name": "Дніпро","name_full_name_ua": "м. Дніпро, Дніпропетровська  обл., Україна", "zone":2},
        {"id": 11,"name": "Львів","name_full_name_ua": "м. Львів, Львівська  обл., Україна", "zone":2},
        {"id": 13,"name": "Одеса","name_full_name_ua": "м. Одеса, Одеська  обл., Україна", "zone":2},
        {"id": 14,"name": "Харків","name_full_name_ua": "м. Харків, Харківська  обл., Україна", "zone":2},
        {"id": 16,"name": "Запоріжжя","name_full_name_ua": "м. Запоріжжя, Запорізька  обл., Україна", "zone":3},
        {"id": 17,"name": "Кривий Ріг","name_full_name_ua": "м. Кривий Ріг, Дніпропетровська  обл., Україна", "zone":3},
        {"id": 21,"name": "Вінниця","name_full_name_ua": "м. Вінниця, Вінницька  обл., Україна", "zone":4},
        {"id": 36,"name": "Маріуполь","name_full_name_ua": "м. Маріуполь, Донецька  обл., Україна", "zone":4},
        {"id": 41,"name": "Полтава","name_full_name_ua": "м. Полтава, Полтавська  обл., Україна", "zone":4}],
    };
  },
  watch:{
    'formData.personalInfo.fields.date_of_birth.value': function(newValue, oldValue) {
      delete this.errors['personal.dateBirth'];
      delete this.errors['personal.identificationCode'];
    },
    'formData.personalInfo.fields.identification_number.value': async function(newValue, oldValue) {
      if (newValue && newValue.length === 10) {
        this.formData.personalInfo.fields.date_of_birth.value = moment('01.01.1900', 'DD.MM.YYYY')
            .add(newValue.substr(0, 5) - 1, 'day')
            .format('DD.MM.YYYY');
      }
      delete this.errors['personal.identificationCode'];
    },
  },
  validations: {
    formData:{
      personalInfo:{
        fields:{
          identification_number:{
            value:{customMinLength(value) {
                if(!value) return true;
                if (this.formData.personalInfo.fields.client_type.value == 2) {
                  return value !== null && value.length == 8;
                }else{
                  return value !== null && value.length == 10;
                }
              }
            }
          }
        }
      },
      contactPerson:{
        fields:{
          contact_persone_phone:{
            value:{
              customMinLength(value) {
                if(!value || value == '+38 (') return true;
                  return value !== null && value.length === 19;
              }}
          },
        },
      },
      contactInfo:{
        fields:{
          phone:{
            value:{
              customMinLength(value) {
                if(!value || value == '+38 (') return true;
                return value !== null && value.length === 19;
              }
            }
          },
          email:{
            value:{
               email,
            }
          },
        },
      }
    }
  },
  created() {
    this.getClientData(this.$route.params.clientId);
  },
  methods: {
    showDateFormat: function(field) {
      return moment(field.created_at).format("DD-MM-YYYY HH:mm");
    },
    showUserType(field){
      return this.userTypesList.find(option => option.id === parseInt(field.value))?.name || "";
    },
    showAgent(field){
      let result = "";
      if(field.value){
        field.value.forEach((value, index) => {
          let agentData = this.formData.agent.fields.user_id.usersData.find(option => option.id === parseInt(value));
          result += "<p>"+((agentData) ? (agentData.surname || "") + " " + (agentData.name || "") + " " + (agentData.patronymic || "") : "") + "</p>";
        });
      }
      return result;
    },
    createNotice(){
      this.isCreatingClient = true;
      this.noteToEdit = {};
      this.noteToEdit.note = "";
      this.showModal = true;
      this.isCreatingClient = false;
    },
    createHistory(){
      this.isCreatingHistory = true;
      this.$alert("Нажаль тут поки що не працює :(");
      this.isCreatingHistory = false;
    },
    editNote(note) {
      this.noteToEdit = note;
      this.showModal = true;
    },
    confirmDelete(note) {
      Vue.$confirm({
        title: 'Увага!',
        message: 'Ви дійсно хочете видалити нотаток?',
        button: {
          yes: 'Так',
          no: 'Скасувати'
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.deleteNote(note);
          }
        }
      })
    },
    saveNote(editedNote) {
      let params = {
        "editedNote" : editedNote,
        "clientId" : this.$route.params.clientId
      };
      saveClientNotes(params)
          .then((response) => {
            if(response.data.status == "success"){
              this.loadNotes();
            }
            this.showModal = false;
          }).catch((error) => {
        this.showModal = false;
      });


    },
    loadNotes(){
      let params = {
        "clientId" : this.$route.params.clientId
      };
      getClientNotes(params)
          .then((response) => {
            if(response.data.status == "success"){
              this.formDataNote.notes.data =  response.data.data;
            }
          }).catch((error) => {
      });
    },
    deleteNote(note) {
      deleteClientNotes(note.id)
          .then((response) => {
            if(response.data.status == "success"){
              this.loadNotes();
            }
            this.showModal = false;
          }).catch((error) => {
        this.showModal = false;
      });

    },
    startEditing(field, blockName) {
      if(field == 'user_id' && this.formData[blockName].fields[field].user_parent_id) return;

      if((field == 'phone' || field == 'contact_persone_phone') && !this.formData[blockName].fields[field].value){
        this.formData[blockName].fields[field].value = "+38 ("
      }

      this.editing = true;
      this.editField = field;
      this.formBuffer[blockName] = { ...this.formData[blockName] };
    },
    handleVisibleChange(value){
      const fieldName = this.$refs.agentSelect[0]?.$attrs['data-field-name'] || null;
      const blockName = this.$refs.agentSelect[0]?.$attrs['data-block-name'] || null;
      if(!value){
        this.saveField(fieldName, blockName)
      }
    },
    saveField(field, blockName) {
      this.submitCheck = true;
      this.formData[blockName].fields[field] = this.formBuffer[blockName].fields[field];
      this.editing = false;
      this.editField = '';
      // Тут вы можете выполнить запрос на бэкенд для сохранения данных
      // Например, через Axios или другой метод работы с API
      let params;

      if(field == 'client_type' && this.formData[blockName].fields[field].value == 2){
        this.formData.personalInfo.fields.identification_number.value = "";
        this.formData.personalInfo.fields.date_of_birth.value = "";
        params = {'clients_id':this.$route.params.clientId,'fields':[{'field':'identification_number', 'blockName':blockName, 'fieldData':this.formData[blockName].fields['identification_number']},{'field':'date_of_birth', 'blockName':blockName, 'fieldData':this.formData[blockName].fields['date_of_birth']}]};
      }
      else if(field == 'identification_number'){
        params = {'clients_id':this.$route.params.clientId,'fields':[{'field':'identification_number', 'blockName':blockName, 'fieldData':this.formData[blockName].fields['identification_number']},{'field':'date_of_birth', 'blockName':blockName, 'fieldData':this.formData[blockName].fields['date_of_birth']}]};
      }else if (field == 'contact_persone_phone' && this.formData[blockName].fields[field].value == ''){
        this.formData.contactPerson.fields.contact_persone_phone.value = "";
        params = {'clients_id':this.$route.params.clientId,'fields':[{'field':field, 'blockName':blockName, 'fieldData':this.formData[blockName].fields[field]}]};
      }else if (field == 'phone' && this.formData[blockName].fields[field].value == ''){
        this.formData.contactInfo.fields.phone.value = "";
        params = {'clients_id':this.$route.params.clientId,'fields':[{'field':field, 'blockName':blockName, 'fieldData':this.formData[blockName].fields[field]}]};
      }
      else{
        params = {'clients_id':this.$route.params.clientId,'fields':[{'field':field, 'blockName':blockName, 'fieldData':this.formData[blockName].fields[field]}]};
      }
      updateClient(params).then((response) => {
        if(response.statusText == "OK"){

        }
      }).catch((error) => {
      });

      this.submitCheck = false;
    },
    getClientData: function(clientId) {
      this.condition.isLoadClientData = true;
      let params = {
        "clientId":clientId,
        "requestType": "getClientData"
      };

      getData(params)
          .then((response) => {
            if(response.data.status == "success"){
              this.client = response.data.data.items[0];
              this.formData.personalInfo.fields.name.value =  this.client.fio;
              this.formData.personalInfo.fields.name_en.value =  this.client.fio_en;
              this.formData.personalInfo.fields.client_type.value =  this.client.client_type;
              this.formData.personalInfo.fields.date_of_birth.value =  this.client.date_of_birth;
              this.formData.personalInfo.fields.identification_number.value =  this.client.identification_number;

              let city = (!this.client.city) ? {id:"",name_full_name_ua:""} : this.client.city;

              this.formData.contactInfo.fields.phone.value =  this.client.phone;
              this.formData.contactInfo.fields.email.value =  this.client.email;
              this.formData.contactInfo.fields.city_id.value =  city;
              this.formData.contactInfo.fields.city_en.value =  this.client.city_en;
              this.formData.contactInfo.fields.street.value =  this.client.street;
              this.formData.contactInfo.fields.street_en.value =  this.client.street_en;
              this.formData.contactInfo.fields.house.value =  this.client.house;
              this.formData.contactInfo.fields.flat.value =  this.client.flat;

              this.formData.contactPerson.fields.contact_persone_name.value =  this.client.contact_persone_name;
              this.formData.contactPerson.fields.contact_persone_phone.value =  this.client.contact_persone_phone;

              this.formData.agent.fields.user_id.value = (this.client.agent) ? this.client.agent.map(item => item.id) : [];
              this.formData.agent.fields.user_id.user_parent_id =  this.client.user_parent_id;
              this.formData.agent.fields.user_id.usersData =  this.client.usersData;

              this.formDataNote.notes.data =  this.client.notes;
              this.condition.isLoadClientData = false;
            }
          }).catch((error) => {
        this.condition.isLoadClientData = false;
      });
    },
    searchCities(query) {
      this.changedCity = true;
      //this.cityRegistration = query;
      if(query.length > 1){
        this.fetchCities(query);
      }
    },
    fetchCities(query) {
      axios.get('/api/directories/cities/find?city=' + query).then(({data}) => {
        this.cities = data.data;
        this.citiesOptions = data.data;
        if (this.citiesOptions.length === 0) {
          this.citiesOptions.push(this.otherCity);
        }
      }).catch(err => (console.log(err)));
    },
    handleClickOutside(event) {
      const clickedElement = event.target;
      const isTextField = clickedElement.classList.contains('text-field') ||
          clickedElement.closest('.text-field') || clickedElement.classList.contains('text-field-edit') ||
          clickedElement.closest('.text-field-edit') ;

      if (!isTextField) {
        // Если клик был сделан вне поля редактирования, завершить редактирование текущего поля
        if (this.editing) {
           if(this.editField == 'phone' && this.formData.contactInfo.fields.phone.value == '+38 ('){
             this.formData.contactInfo.fields.phone.value = "";
           }
           if(this.editField == 'contact_persone_phone' && this.formData.contactPerson.fields.contact_persone_phone.value == '+38 ('){
             this.formData.contactPerson.fields.contact_persone_phone.value = "";
           }
          // Ваша логика сохранения данных поля перед выходом
          this.editing = false; // Установите флаг редактирования в false
          this.editField = ''; // Очистите editField
        }
      }
    },
    onlyLatinCharacters(str) {
      if(str == null || str == "") return true;
      return /^[a-zA-Z ]+$/.test(str);
    },
    errorTaxNumber: function () {

      if (this.formData.personalInfo.fields.client_type.value == 2) {
        return  false;
      }

      if(this.formData.personalInfo.fields.identification_number.value == "" || this.formData.personalInfo.fields.identification_number.value == null) {
        return false;
      }

      if(this.formData.personalInfo.fields.date_of_birth.value == "" || this.formData.personalInfo.fields.date_of_birth.value == null) {
        return false;
      }

      if(this.formData.personalInfo.fields.date_of_birth.value.length == 10 && this.formData.personalInfo.fields.identification_number.value.length == 10 && ! this.validateTaxNumber(this.formData.personalInfo.fields.identification_number.value, this.formData.personalInfo.fields.date_of_birth.value)) {
        this.errors['personal.identificationCode'] = ["ІПН та Дата народження не співпадають"];

        return true;
      }

      return false;
    },
    validateTaxNumber(tax_number, date) {
      let tax_numberDate = moment('01.01.1900', 'DD.MM.YYYY').add(tax_number.substr(0, 5) - 1, 'day');
      let birthDate = moment(date, 'DD.MM.YYYY');

      return tax_numberDate.isSame(birthDate, 'year')
          && tax_numberDate.isSame(birthDate, 'month')
          && tax_numberDate.isSame(birthDate, 'day');
    },
    LetterMask(e){
      let char = String.fromCharCode(e.keyCode); // Get the character
      if(/^[A-Za-z ]+$/.test(char)) {
        this.LetterMaskError = false;
        return true;
      } // Match with regex
      else {
        this.LetterMaskError = true;
        e.preventDefault();
      }
    },
    errorBirthDate: function () {
      if(this.formData.personalInfo.fields.date_of_birth.value == "" || this.formData.personalInfo.fields.date_of_birth.value == null || this.formData.personalInfo.fields.date_of_birth.value == "") {
        return false;
      }

      if((parseInt(moment(this.formData.personalInfo.fields.date_of_birth.value, 'DD.MM.YYYY').diff(moment(), 'years')) * -1) < 18) {
        this.errors['personal.dateBirth'] = ["Страхувальнику повинно бути більше 18 років"];
        return true;
      }else if((parseInt(moment(this.formData.personalInfo.fields.date_of_birth.value, 'DD.MM.YYYY').diff(moment(), 'years')-1) * -1) > 100) {
        this.errors['personal.dateBirth'] = ["Страхувальнику повинно бути не більше 100 років"];
        return true;
      }

      return false;
    },
  },
};
</script>

<style scoped>
.column-block {
  a {
    .text {
      margin-left: 5px;
      line-height: 26px;
    }
  }
}
.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: .25;
  height: 100%;
}

.clients.info-block {
  padding: 20px;
  padding-bottom: 0px;
  border: none;
}

.clients.info-block .block{
  border-radius: 3px;
}
.clients.info-block .head {
  height: 51px;
  background: #F6F7FB;
}
.clients.info-block .head h2{
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100%;
  padding-left: 15px;
}
.clients.info-block .body {
  padding: 15px;
}
.interactive-form{
  p {
    display: inline;
    border: none;
    background: none;
    cursor: pointer;
  }
  input {
    /*border: none;*/
    outline: none;
    background: none;
  }
  .text-field{
    p{
      display: block;
    }
  }
}

.interactive-form .text-field .text-label, .interactive-form .text-field .text-label-none-edit{
  width: 100%;
  padding: 7px;
  border-radius: 3px;
  min-height: 36.5px;
}
.interactive-form .text-field .text-label:hover{
  background: #FFAD3D33;
  cursor: text;

}

.info-block h2{
  font-weight: 700;
}
.info-block .label-field{
  font-size: 15px;
  font-weight: 700;
}

.info-block .label-field.grey{
  color: #999DA6;
}


.info-block .text-field .text-label.invalid{
  border: 1px solid red;
  border-radius: 3px;
}

input.invalid{
  border: 1px solid red!important;
  border-radius: 3px!important;
}

.info-block .text-field-edit{
  font-size: 15px;
  font-weight: 700;
  color: #24282C;
  height: 36.5px;
  margin-bottom: 22px;
}
.info-block .text-field.grey, .info-block .text-field-edit.grey{
  color: #999DA6;
}
.info-block .date-field{
  font-size: 15px;
  font-weight: 300;
  color: #999DA6;
}
.info-block .text-field-edit input, .info-block .text-field-edit select {
  border: 1px solid #FFAD3D;
  border-radius: 3px;
  width: 100%;
  text-align: left;
  background: #FFFFFF;
  padding: 7px;
}
.info-block  button {
  border: none;
  background: transparent;
  padding-top: 8px;
}
.info-block .text-field.grey button {
  padding-top: 0px;
}


</style>
<style>
.clients.info-block{
  .el-select__tags{
    justify-content: right;
  }
  .form-group .form-control.choice_city {
    border-color: #ffad3d;
  }
  .vc-container{
    border-radius: 3px;
  }
}

</style>
