  <template>
    <div class="p-0 clients">
    <div class="row top-buttons">
      <div class="col-auto pr-0">
        <router-link :to="{ name: 'clients'}" class="go-back">
          Повернутися до списку клієнтів
        </router-link>
      </div>
      <div v-if="accessDenied" class="col-12 pr-0 access-denied">
        <h2 class="error text-center">
          У вас немає доступу до цьго кліенту</h2>
      </div>
      <div data-v-133326c9="" class="col-auto  ml-auto d-none d-md-block"></div>
      <div class="col-auto pl-0 right-blok">
        <div v-if="loadTopMoreOptions == true"
             class="option-loader spinner-border"
             style="height: 14px; width: 14px; margin-bottom: 4px;"
             role="status">
        </div>
        <top-more-options v-else @delete-client="deleteClient()">
        </top-more-options>
      </div>
    </div>

    <div class="list-orders container p-0">
      <div v-if="!condition.isMobile" class="tabs-block">
        <ul class="status-tabs">
           <li @click="(status.disabled == true ? null : activateTab(status))"  v-for="(status, index) in clientStatuses" :class="'tab'+ (status.disabled == true ? ' disabled' : '') ">
            <a v-if="status.disabled == true" v-bind:class="['custom__item', { active: listTabsQuery.statusId === status.id }]"
               href="javascript:void(0);"> {{ status.name || 'Всі' }}<div class="label">
              Скоро
            </div></a>
            <a v-else v-bind:class="{ active: listTabsQuery.statusId === status.id }"
               href="javascript:void(0);"> {{ status.name || 'Всі' }}</a>
          </li>
        </ul>
      </div>
      <div v-else>
        <div class="form-group">
          <el-select v-model="listTabsQuery.statusId"
                     class="form-control-el"
                     placeholder="Виберіть статус">
            <el-option v-for="item in clientStatuses"
                       :key="item.id"
                       :label="item.name || 'Всі'"
                       :value="item.id"
                       :disabled="item.disabled"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div v-if="isAllTab()" class="list-clients container p-0">
        <all-tab />
      </div>
      <div v-else-if="isOrdersListTab()" class="list-clients container p-0">
        <div class="insurance-client__menu__filters__block">
            <div class="row align-items-center mt-4">
              <div class="col-auto pr-0">
              <history-filter-block :is-load-xml="condition.idLoadXml"
                                  @download-xls="downloadXls()"
                                  :is-load-data="condition.isLoadTable"
                                  :orders="orders"
                                  @init-orders-list="initList(1)"
                                  ref="filter"></history-filter-block>
              </div>
              <div class="col-auto  ml-auto d-none d-md-block">
                <!-- Пропуск или пустое место -->
              </div>
              <div class="col-auto ml-auto">
                <button v-if="showCreateNewOrderBtn" @click="createNewOrder()">
                  <div v-if="isCreatingNewPolis"
                       class="option-loader spinner-border"
                       style="height: 22px; width: 22px; margin-right: 12px;"
                       role="status">
                  </div>
                  <svg class="keep-fill" data-v-f9679148="" width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><g data-v-f9679148="" filter="url(#filter0_d_557_377)"><rect data-v-f9679148="" x="4" y="1.80005" width="30" height="28.2" rx="3" fill="#FFAD3D"></rect><rect data-v-f9679148="" x="4" width="30" height="28.2" rx="3" fill="#FFAD3D"></rect></g><rect data-v-f9679148="" x="18.3999" y="9" width="1.2" height="12" fill="white"></rect><rect data-v-f9679148="" x="25" y="14.4" width="1.2" height="12" transform="rotate(90 25 14.4)" fill="white"></rect><defs data-v-f9679148=""><filter data-v-f9679148="" id="filter0_d_557_377" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood data-v-f9679148="" flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix data-v-f9679148="" in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset data-v-f9679148="" dy="4"></feOffset><feGaussianBlur data-v-f9679148="" stdDeviation="2"></feGaussianBlur><feColorMatrix data-v-f9679148="" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix><feBlend data-v-f9679148="" mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_557_377"></feBlend><feBlend data-v-f9679148="" mode="normal" in="SourceGraphic" in2="effect1_dropShadow_557_377" result="shape"></feBlend></filter></defs></svg>
                </button>
              </div>
            </div>
          </div>
        <orders-list ref="clientOrderList"
                     @init-orders-list="initList()"
                     @filter-status-wrap="filterStatusWrap()"
                     :count-prolongation="countProlongation"
                     :order-statuses="clientOrdersSubStatuses"
                     :is-load-data="condition.isLoadTable"
                     :is-mobile="this.condition.isMobile"
                     :orders="orders"></orders-list>
        <div style="text-align: center">
            <pagination v-show="total > 0" :total="total"
                        :page.sync="listQuery.page"
                        :limit.sync="listQuery.limit" @pagination="initList()" />
        </div>
      </div>
      <div v-else-if="isObjectsListTab()" class="list-clients container p-0">
        <object-list-tab />
      </div>
      <div v-else-if="isDocumentsListsTab()" class="list-clients container p-0">
        DocumentsLists - {{ client }}
      </div>

    </div>
    <modal-get-invoice-order ref="modalGetInvoiceOrder"></modal-get-invoice-order>
    <modal-get-instant-loan ref="modalGetInstantLoan"></modal-get-instant-loan>
    <modal-get-mono-pay-parts ref="showModalGetMonoPayParts"></modal-get-mono-pay-parts>
    <modal-get-all-payment-ways
        ref="modalGetAllPaymentWays"
        @showModalGetInvoiceOrder="getPaymentInvoice"
        @showModalGetInstantLoan="getInstantLoan"
        @showModalGetMonoPayParts="showModalGetMonoPayParts"
        @showModalGetInvoiceLegal="showModalGetInvoiceLegal"
        :is-legal-type="false"
    >
    </modal-get-all-payment-ways>
  </div>
</template>


<script>
import Vue from "vue";
import {MoreOptions, RowBlockSkeleton, RowMobileBlockSkeleton, ClientInfo, ModalEditNote, AllTab, ObjectListTab, TopMoreOptions} from './components'
import {MoreOptions as HistoryMoreOptions, RowBlockSkeleton as HistoryRowBlockSkeleton, RowMobileBlockSkeleton as HistoryRowMobileBlockSkeleton, ClientInfo as HistoryClientInfo} from '../history/components'
import {clientMixin, orderMixin, HistoryMixin, customerMixin} from '@/mixin'
import {deleteClient, getData} from '../../api/client';

import { default as HistoryFilterBlock } from "@/views/history/filterBlock";
import {GetPaymentLink as ModalGetInvoiceOrder, GetInstantLoan as ModalGetInstantLoan, GetMonoPayParts as ModalGetMonoPayParts, GetAllPaymentWays as ModalGetAllPaymentWays} from "@/components/app/modals/order";
import { default as ordersList } from "@/views/history/ordersList";
import {
  concludeOrder,
  downloadContract,
  downloadExcel,
  downloadOrder,
  list as listOrders,
} from "@/api/order";
import {downloadBusContract} from "@/api/downloadBusContract";
import {downloadGreenCardContract} from "@/api/downloadGreenCardContract";
import {downloadTourismContract} from "@/api/downloadTourismContract";
import Pagination from '@/components/Pagination';

const FileDownload = require('js-file-download');

export default {
  mixins: [ clientMixin, orderMixin, HistoryMixin, customerMixin ],
  components: {MoreOptions, RowBlockSkeleton, RowMobileBlockSkeleton, ClientInfo, HistoryMoreOptions, HistoryRowBlockSkeleton, HistoryRowMobileBlockSkeleton, HistoryClientInfo, HistoryFilterBlock, ModalEditNote, AllTab, ObjectListTab, TopMoreOptions, ModalGetInvoiceOrder, ModalGetInstantLoan, ModalGetMonoPayParts, ModalGetAllPaymentWays,ordersList,Pagination},
  props: {
  },

  data: function () {
    return {
      mobileWith: 992,
      isCreatingNewPolis: false,
      countProlongation: 0,
      accessDenied: false,
      showCreateNewOrderBtn: false,
      orders: {
        type: Array,
        default: () => {
          return [{
            dgo: {},
            statusId: null,
            fullName: null,
            earning: null,
            contract: {},
            contractDgo: {},
            companyInfo: {},
            insurant: {},
            insured: {},
            price: null,
            dgoPrice: null,
            discountPrice: null,
            cashbackPrice: null,
          }];
        },
      },
      condition: {
        isLoadClientData: false,
        isLoadTable: true,
        isLoadXml: false,
        isMobile: false,
      },
      listTabsQuery: {
        statusId: 1,
      },
      total: 1,
      listQuery: {
        page: 1,
        limit: 10,
      },
      listSubQuery: {
        statusId: 0,
      },
      listTableQuery: {
        page: 1,
        limit: 10,
      },
      client: null,
      loadMoreOptions: {},
      loadTopMoreOptions: false,

    }
  },
  async created() {
    window.addEventListener("resize", this.checkWindowResize);
    this.checkIsMobile();

    //this.client = this.$route.params.clientData;
    //this.client = this.$route.params.clientId;



    this.initList();
  },
  computed: {
    orderTypes: function () {
      return this.$store.getters.ORDER_TYPES
    },
  },
  watch: {
    'listSubQuery.statusId': function (newValue, oldValue) {
      this.initList(newValue);
    },
  },
  methods: {

    checkIsMobile: function() {
      return this.condition.isMobile = window.innerWidth < this.mobileWith;
    },
    checkWindowResize: function() {
      this.checkIsMobile()
    },
    deleteClient: async function () {
      Vue.$confirm({
        title: 'Увага!',
        message: 'Ви дійсно хочете видалити клієнта?',
        button: {
          yes: 'Так',
          no: 'Скасувати'
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            let params = {clients_id:this.$route.params.clientId};
            deleteClient(params).then((response) => {
              if(response.data.status == "success"){
                this.$router.push('/clients')
              }
            }).catch((error) => {
              this.condition.isLoadTable = false;
            });
          }
        }
      })

    },
    fullClientInfo: async function(client) {
      //this.$router.push({name: 'client_info', params: {clientId: client.id}})
    },
    copyClient: function(client) {
    },
    showDateFormat: function(field) {
      return moment(field.created_at).format("DD-MM-YYYY HH:mm");
    },
    showClientInfo() {
      this.$refs.clientInfo.handle(true);
    },
    filterStatusWrap: function() {
      this.$refs.filter.filterStatusWrap(true);
    },
    isDisabledTab: function () {
      return this.listTabsQuery.statusId === this.allInfo;
    },
    isAllTab: function () {
      return this.listTabsQuery.statusId === this.allInfo;
    },
    isOrdersListTab: function () {
      return this.listTabsQuery.statusId === this.ordersList;
    },
    isObjectsListTab: function () {
      return this.listTabsQuery.statusId === this.objectsList;
    },
    isDocumentsListsTab: function () {
      return this.listTabsQuery.statusId === this.documentsLists;
    },
    activateTab :function (status) {
      this.$set(this.listTabsQuery, 'statusId', status.id);
    },
    activateSubTab :function (status) {
      this.$set(this.listSubQuery, 'statusId', status.id);
    },
    initList: function (filterButtonEvent) {
      let query = {};
      if (filterButtonEvent === 1) {
        this.listTableQuery.page = 1;
        query = this.getQuery();
      }

      this.orders = [];
      this.total = 0;
      this.condition.isLoadTable = true;

      let params = {
        "clientId":this.$route.params.clientId,
        "requestType": "orders",
        "active_orders": false,
        "listSubQueryStatusId": this.listSubQuery.statusId,
      };

      if(this.$refs.clientOrderList?.listQuery){
        switch (this.$refs.clientOrderList?.listQuery.statusId){
          case 0:
            params.active_orders = false;
            break;
          case 1:
            params.active_orders = true;
            break;
          default:
            params.active_orders = false;
            break;
        }
      }else{
        params.active_orders = false;
      }
      params = {...params, ...query};
      getData(params)
          .then((response) => {
            const { data }  = response;
            this.orders = data.data.items;
            this.total = data.data?.total;
            this.condition.isLoadTable = false;
          }).catch((error) => {
            if(error.response.data.status == "auth"){
              this.accessDenied = true;
            }
            this.condition.isLoadTable = false;
      });

    },
    getQuery: function () {
        return {...this.$refs.filter.listQuery, ...this.$refs.clientOrderList?.listQuery, ...this.listQuery};
    },
    downloadXls : async function() {
      Vue.set(this.condition, 'idLoadXml', true);

      let response = await downloadExcel(this.getQuery());

      if (response?.data) {
        FileDownload(response.data, 'report.xlsx');
      }

      Vue.set(this.condition, 'idLoadXml', false);
    },
    isSubAllTab: function () {
      return this.listSubQuery.statusId === this.allSubInfo;
    },
    isSubActiveOrdersTab: function () {
      return this.listSubQuery.statusId === this.activeSubInfo;
    },
    createNewOrder: function () {
      this.isCreatingNewPolis = true;
      this.$alert("Нажаль тут поки що не працює :(");
      this.isCreatingNewPolis = false;
    }
  },
}
</script>
<style lang="scss" scoped>
.clients .custom__item{
  position: relative;
}
.clients .custom__item .label{
  position: absolute;
  right: -36px;
  top: -16px;
  z-index: 2;
  color: #EF4137;
  text-transform: uppercase;
  text-align: center;
  font-size: 10px;
  line-height: normal;
  font-weight: bold;
  height: 15px;
  padding: 0 4px;
  border: 1px solid #EF4137;
  border-radius: 3px;
}
.clients li.disabled a{
  color:#999DA6!important;
}
.clients main a.go-back {
  margin: -16px 0px 20px 0;
}
.clients .vc-container{
  border-radius: 3px;
}

.clients button{
  border: none;
  background-color: transparent;
}

@media screen and (max-width: 1220px){
  .clients .list-clients.container.p-0, .clients .top-buttons {
    max-width: 960px;
    width: 960px;
    margin: 0 auto;
  }
  .clients .row.top-buttons .col-auto:first-child,
  .clients .row.top-buttons .col-auto:last-child,
  {
    padding:0px
  }
}
@media screen and (max-width: 991px){
  .clients .right-blok .bottom-block{
   /* margin: -16px 0px 20px 0;*/
  }
}
@media screen and (max-width: 991px){
  .clients .list-clients.container.p-0, .clients .top-buttons {
    max-width: 100%;
    width: 100%;
  }
}
@media screen and (max-width: 767px){
  .clients .top-buttons .right-blok{
    left: calc(100% - 454px);
  }
}
@media screen and (max-width: 483px){
  .clients .top-buttons .right-blok{
    margin-bottom: 18px;
    left: 0px;
  }
}
</style>
